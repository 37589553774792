import { useState, useCallback } from "react";
import { useTimer } from "./useTimer";
/*
 * Cycles through `slide` every `interval` milliseconds
 * Returns the currently active slide
 * Ensure `slides` are memoized
 */
export const useSlideshow = (slides, interval, startIndex = 0) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(startIndex);
  const tick = () => {
    setActiveSlideIndex(activeSlideIndex => {
      if (activeSlideIndex === slides.length - 1) {
        return 0
      } else {
        return activeSlideIndex + 1
      }
    })
  };
  const tickCallback = useCallback(() => tick(), [slides])
  useTimer(tickCallback, interval);
  return slides[activeSlideIndex];
};
