import { useEffect } from "react";
/*
 * Calls `callback` at every `interval` milliseconds
 * Note - ensure `callback` is memoized (e.g. useCallback)
 */
export const useTimer = (callback, interval) => {
  useEffect(() => {
    const intervalId = setInterval(callback, interval);
    return () => { clearInterval(intervalId); };
  }, [interval, callback]);
};
