import React, { useMemo, useCallback } from 'react'
import useTypeWriter from '@tehlordvortex/react-typewriter-hook'
import { useTimer } from './useTimer';


export const useSerialTypeWriter = (strings, { interval = 1000, duration = 300, startIndex = 0 } = {}) => {
  const [activeStringIndex, setActiveStringIndex] = React.useState(startIndex)
  const activeString = useMemo(() => strings[activeStringIndex], [activeStringIndex])
  const typedString = useTypeWriter(activeString, {
    duration: duration,
  })
  const nextString = () => {
    setActiveStringIndex(activeStringIndex => {
      if (activeStringIndex === strings.length - 1) {
        return 0
      } else {
        return activeStringIndex + 1
      }
    })
  }
  const nextStringCallback = useCallback(() => nextString(), [strings])
  useTimer(nextStringCallback, interval)
  
  return (
    typedString
  )
}