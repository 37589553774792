import React from "react"
import classNames from "classnames"
import { useSlideshow } from "../hooks/useSlideshow"

import classes from "./SlideShow.module.css"

export const SlideShow = ({ slides, interval = 3000 }) => {
  const slideKeys = React.useMemo(() => slides.map(slide => slide.key), [
    slides,
  ])
  const activeSlide = useSlideshow(slideKeys, interval)
  return slides.map(slide => (
    <div
      className={classNames(
        classes.slide,
        activeSlide === slide.key && classes.activeSlide
      )}
      key={slide.key}
    >
      {slide.component}
    </div>
  ))
}
