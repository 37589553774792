import React from "react"

import SEO from "../components/seo"
import HomePageLayout from "../components/layouts/HomePageLayout"

import "./index.css"
import SocialLinks from "../components/SocialLinks"
import { SlideShow } from "../components/SlideShow"
import { BackgroundImage } from "../components/BackgroundImage"
import { useSerialTypeWriter } from "../hooks/useSerialTypeWriter"

const SLIDE_DURATION = 7500

const backgroundSlides = [
  {
    key: "people",
    component: <BackgroundImage imageName="people-background.jpg" />,
  },
  {
    key: "processes",
    component: <BackgroundImage imageName="processes-background.jpg" />,
  },
  {
    key: "technology",
    component: <BackgroundImage imageName="technology-background.jpg" />,
  },
]

const strings = ["people", "processes", "technology"]

// const slides = [
//   {
//     key: "people",
//     component: <span className="hero-highlight">people</span>,
//   },
//   {
//     key: "processes",
//     component: <span className="hero-highlight">processes</span>,
//   },
//   {
//     key: "technology",
//     component: <span className="hero-highlight">technology</span>,
//   },
// ]

const HighlightedText = () => {
  const string = useSerialTypeWriter(strings, {
    interval: SLIDE_DURATION,
  })
  return <span className="hero-highlight">{string}</span>
}

const IndexPage = () => {
  return (
    <HomePageLayout>
      <SEO title="Home" />
      <main className="hero">
        <div className="hero-background">
          <SlideShow slides={backgroundSlides} interval={SLIDE_DURATION} />
        </div>
        <div className="hero-content">
          <div className="container">
            <h1 className="hero-text">
              we are passionate about{" "}
              <span className="hero-highlight">
                <HighlightedText />
              </span>
            </h1>
            <p className="hero-subtitle">
              Cloud Solutions | Data Analytics &amp; AI | IoT | Cybersecurity |
              Hardware & Infrastructure
            </p>
            <SocialLinks className="hero-social-links" />
          </div>
        </div>
      </main>
    </HomePageLayout>
  )
}

export default IndexPage
